@import "src/theme/theme-variables";

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
}

// Content Fixed
.content-fixed {
  height: calc(100vh - 108px);
  overflow-y: auto;
}

.content-fixed-02 {
  height: calc(100vh - 132px);
  overflow-y: auto;
}

.content-fixed-03 {
  background: none;
  border: none !important;
  box-shadow: none !important;
  height: calc(100vh - 132px);
  overflow-y: auto;
  padding: 0 !important;
}

.content-fixed-04 {
  background: none;
  border: none !important;
  box-shadow: none !important;
  height: calc(100vh - 65px);
  overflow-y: auto;
  padding: 0 !important;
}

.content-fixed-buttons {
  padding: 8px 0;
}

// List Table Fixed
.mat-card.list-table-fixed {
  height: calc(100vh - 132px);
  padding: 0;

  .mat-card-content {
    height: calc(100vh - 172px);
    margin: 0;
    overflow-y: scroll;
  }
}

//List Table Fixed for tables into mat-tabs
.mat-card.mat-card.list-table-fixed-mat-tab {
  height: calc(100vh - 172px);
  .mat-card-content {
    height: calc(100vh - 172px);
    overflow-y: scroll;
  }
}

.mat-card.list-table-fixed-notPaginator {
  height: calc(100vh - 132px);
  padding: 0;

  .mat-card-content {
    margin: 0;
  }

  .mat-card-content {
    height: calc(100vh - 132px);
    margin: 0;
    overflow-y: scroll;
  }
}

.list-table-notFound {
  color: $app-background-dark;
  font-size: 20px;
  span{
    padding-left: 8px;
    color: $app-background-dark;
  }
}


// Custom Table
.customTabFull {
  overflow-y: auto;

  .mat-tab-body {
    height: calc(100vh - 151px)
  }

  .mat-tab-label {
    height: 40px;
  }

  .mat-tab-label-active {
    background-color: $app-background-dark;
    color: #fff;
    opacity: 1 !important;
  }

  .mat-tab-header {
    max-width: calc(100vw - 259px)
  }
}

// Custom Table
.customTable {
  .mat-row:nth-child(odd) {
    background-color: #E9EAEC;
  }

  .mat-row.highlight {
    background: #8bd;
  }

  .mat-header-row {
    position: sticky;
    top: 0;
    z-index: 5;
    line-height: 16px;
    height: 48px
  }

  .mat-column-actions {
    text-align: center;
    width: 0;
  }

  tr th,
  tr td {
    padding: 0 8px;
    height: 100%;
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell{
    padding-left: 16px;
    color: $app-background-dark;
  }
  th.mat-header-cell{
    font-weight: bold;
  }
  // th.mat-header-cell:last-of-type,
  // td.mat-cell:last-of-type,
  // td.mat-footer-cell:last-of-type {
  //   padding-right: 16px;
  //   color: $app-background-dark;
  //   font-weight: bold;
  // }

  .disabled-cell td {
    color: #777;
  }

  .mat-button {
    height: 32px !important;
    min-width: 32px !important;
    width: 48px !important;
    font-size: 12px !important;
    line-height: 12px;
    padding: 0;
  }

  .col-bottom {
    color: #999;
    font-size: small;
    margin-top: -16px;
  }

  .col-min {
    width: 0;
  }

  .col-amount {
    text-align: right;
    width: 0;
    white-space: nowrap;
  }

  .col-nowrap {
    width: 0;
    white-space: nowrap;
  }

  .col-total {
    font-weight: bold;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .center {
    text-align: center;
  }

  .icon {
    text-align: center;
    vertical-align: bottom;
  }

  .positive {
    color: green;
  }

  .negative {
    color: red;
  }

  .disabled {
    color: #969696;
  }
}

.customTable.small {

  .mat-row,
  .mat-header-row,
  .mat-footer-row {
    height: 32px;
  }
}

.customTable.large-h {

  td.mat-cell,
  th.mat-header-cell,
  td.mat-footer-cell {
    width: auto;
    white-space: nowrap;
    
  }
}

// Custom Paginator
.customPaginator {
  .mat-form-field-wrapper {
    padding: 0px;
    margin: 0;
  }

  .mat-paginator-page-size-select {
    margin: 0px 0px 0px 0px;

  }

  .mat-form-field-flex {
    height: 32px;
    margin-top: 4px;

  }

  .mat-form-field-infix {
    border-top: 0px;
    padding: 4px 0px;
    margin-top: 0px;
  }

  .mat-paginator-container {
    border-top: 1px solid #eee;
    background-color: #F3F3F5;
    min-height: 30px;
    height: 40px;
  }

  .mat-paginator-page-size-label {
    margin: 8px 16px 0 16px;
    font-size: 16px;
  }

  .mat-paginator-range-label {
    font-size: 16px;
    margin-top: 4px;
  }

  .mat-select-arrow-wrapper {
    padding-top: 8px;
  }
}

// Titles
.title-01 {
  font-size: 20px;
  color: #857100;
  margin-bottom: 0 !important;
}

// Icons
.filename-icon {
  width: 20px;
  height: auto;
}

// Full height
.full-height {
  height: calc(100vh - 60px);

  .content {
    height: 100%;
    overflow-y: scroll;
  }
}


/* Chrome Scroll */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: $app-background-dark;
}

/* Firefox Scroll*/
* {
  scrollbar-color: $app-background-dark transparent;
  scrollbar-width: thin !important;
}

/* Chrome, Safari, Edge, Opera  Number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox Number*/
input[type="number"] {
  -moz-appearance: textfield;
}


// MATERIAL OVERWRITE STYLES ===================================================
// =============================================================================

// Custom material container
.mat-container {
  height: calc(100vh - 56px);
  overflow-y: auto;
  padding: 0 8px;
}

// Ovewrite mat-menu styles
.mat-menu-panel .mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-list-item-content {
  padding: 0 8px !important;
}

// Ovewrite mat-card-tile size font title
.mat-card-title {
  font-size: 20px !important;
}

// Ovewrite mat-form-field width auto
 .mat-form-field-infix {
   width: auto !important;
 }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 8px 0;
}

// Ovewrite mat-tooltip style
.mat-tooltip {
  color: #eee !important;
  border-radius: 4px;
  background-color: #585454;
  border: 1px solid #666;
  font-size: 16px;
  padding: 0 16px !important;
}

// Custom option with suboption style
.mat-option-text span {
  line-height: 1rem;
}

.mat-option-text .mat-suboption-text {
  font-size: 10px;
  color: $app-background-text;
}

.mat-icon-button {
  line-height: 24px !important;
}

.mat-raised-button {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

// Height header container
.header-container {
  height: 56px;
}

// Modal container
.mat-dialog-container {
  width: 80vw !important;
}

// Modal container responsive
.mat-dialog-responsive {
  max-height: 90vh;
  min-width: 80vw;

  .mat-dialog-container {
    padding: 8px 16px 16px 16px;

    .mat-dialog-title {
      margin: 0 0 8px;
    }

    background-color: $app-background-light;
  }

  .mat-dialog-content {
    max-height: calc(100vh - 256px);
    padding: 0 16px;
    margin: 0 -16px;
  }

  .mat-dialog-actions {
    padding: 16px 0;
    margin-bottom: -16px;
  }
}

.mat-dialog-responsive-confirm {
  max-height: 90vh;
  min-width: 20vw;

  .mat-dialog-container {
    padding: 8px 16px 16px 16px;
    width: auto !important;
    min-width: 20vw;

    .mat-dialog-title {
      margin: 0 0 8px;
    }

    background-color: $app-background-light;
  }

  .mat-dialog-content {
    max-height: calc(100vh - 256px);
    padding: 0 16px;
    margin: 0 -16px;
  }

  .mat-dialog-actions {
    padding: 16px 0;
    margin-bottom: -16px;
  }
}

// Modal container full
.mat-dialog-full {
  max-width: 100vw !important;
  max-height: 100vh !important;

  .mat-dialog-container {
    width: auto !important;
    padding: 16px;
  }

  .mat-dialog-content {
    max-height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

// Custom mat-form-field
.mat-form-field-wrapper {
  padding: 8px 0;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 0 8px 0 !important;
}

.mat-error {
  padding-top: 8px;
}

.mat-focused .mat-form-field-required-marker {
  color: mat.get-color-from-palette($app-primary);
}

// Custom mat-checkbox
.mat-checkbox {
  line-height: 24px;
  margin-bottom: 8px;
}

.mat-checkbox .mat-checkbox-ripple {
  left: calc(50% - 12px) !important;
  top: calc(50% - 12px) !important;
  height: 24px !important;
  width: 24px !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}


//Notifications styles
.snackbar-success {
  background: mat.get-color-from-palette($app-primary);

  span {
    color: #fff;
    font-weight: 300;
  }

  button>span {
    color: #fff;
    font-weight: 500;
  }
}

.snackbar-warn {
  background: mat.get-color-from-palette($app-primary);

  span {
    color: #fff;
    font-weight: 300;
  }

  button>span {
    color: #fff;
    font-weight: 500;
  }
}

.snackbar-error {
  background: mat.get-color-from-palette($app-warn);

  span {
    color: #fff;
    font-weight: 300;
  }

  button>span {
    color: #fff;
    font-weight: 500;
  }
}

// =============================================================================

.mat-drawer-inner-container {
  background-color: $app-background-dark;
}

.mat-expansion-panel-header {
  height: 48px;

  &.mat-expanded {
    height: 48px;
  }
}

//Error Notification Snackbar
.warn-snackbar {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;

  .mat-simple-snackbar-action {
    color: #8a6d3b;
  }
}

.error-snackbar {
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;

  .mat-simple-snackbar-action {
    color: #a94442;
  }
}

.success-snackbar {
  color: #3c763d;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;

  .mat-simple-snackbar-action {
    color: #3c763d;
  }
}

.info-snackbar {
  color: #31708f;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;

  .mat-simple-snackbar-action {
    color: #31708f;
  }
}

.app-error {
  background-color: #f2dede;

  .text {
    color: #a94442;
  }
}

// Calendar 
.mat-calendar-body-selected {
  background-color: #535252;
  color: white;
}

// =============================================================================

// Fixed Filters
.fixed_filters_div {
  padding: 0px 8px !important;
  margin-bottom: 8px !important;

  .mat-form-field-wrapper {
    margin: 0 !important;
  }
}

// To reduce size of mat form field inside the table
.table-input-div{
  font-size:14px
}

// Sidenav
.mat-list-item {
  div{
    width:100%
  }
  span{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    width:100%
  }

}

.emojiScore{
  height:18px; 
  width:18px;
}

