/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.
@use "@angular/material" as mat;
@import "@angular/material/theming";

@import "palette";
@import "typography";

@include mat-core($inklusiobat-typography);

//Custom palette

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat-palette($inklusiobat-primary-color, A901);
// stylelint-disable-next-line value-keyword-case
$app-accent: mat-palette($inklusiobat-primary-color, A901);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Background colors
$app-background-dark: #383838; //Fondo oscuro (menu)
$app-background-light: #ECECEC; //Fondo componentes

$app-background-grey: #E9EAEC; //Filas tablas

$app-background-text-light: #F7F7F7; //Tipografía clara
$app-background-text: #67666A; //Tipografía oscura


.mat-inklusiobat-green{ //Botón editar
    color: #F7F7F7;
    background-color: #45A941
}

.mat-inklusiobat-blue{ //Botón ver
    color: #00529C;
    background-color: #F7F7F7;
    border: 1px solid #00529C !important;
}

.mat-inklusiobat-red{ //Botón eliminar
    color: #EF3F30;
    background-color: #F7F7F7;
    border: 1px solid #EF3F30 !important;
}


.mat-inklusiobat-button{
    color: #F7F7F7;
    background-color: #383838;
}

.mat-inklusiobat-grey{
    color: #F7F7F7;
    background-color: #585454;
}

.mat-inklusiobat-dialog{
    color: #F7F7F7;
    background-color: #67666A;
}

.mat-inklusiobat-dark{
    color: #F7F7F7;
    background-color: #1c1c1c;
}